let intersectionObserver = null;
let resizeObserver = null;
const isDesktop = !app.device.isTabletUserAgent() && !app.device.isMobileUserAgent();
const parallaxSettings = {
	defaultTopImageStaticThreshold: 0
};

let lastScrollTop = 0;
let imgTranslateY = 0;

const cache = {
	parallaxContainer: $('.js-parallax'),
	parallaxContainerSel: '.js-parallax',
	stickyContainerSel: '.js-sticky_container',
	stickyFrameSel: '.js-sticky-frame',
	parallaxContainerClass: 'js-parallax',
	stickyContainerClass: 'js-sticky_container',
	stickyFrameClass: 'js-sticky-frame'
};

class StickyColorContent {
	/**
	 * Create a StickyColorContent effect by cloning the sticky element and using css clip-path: polygon(...)
	 * @param {Object} config - Configuration object.
	 * @param {Element} config.moduleConstructorWrapper - The wrapper element for the module constructor.
	 * @param {Element} config.stickyColorBlock - The sticky color block element.
	 */
	constructor(config) {
		this.moduleConstructorWrapper = config.moduleConstructorWrapper;
		this.stickyColorBlock = config.stickyColorBlock;
		this.frame = this.moduleConstructorWrapper.querySelector(cache.stickyFrameSel);
		this.mediaBlock = this.moduleConstructorWrapper.querySelector(cache.parallaxContainerSel);

		if (this.frame && this.moduleConstructorWrapper.classList.contains(cache.stickyContainerClass)) {
			initStickyColorContentLoad(this.stickyColorBlock);
			initStickyColorContentEvents(this.stickyColorBlock, this.frame, this.mediaBlock);
		}
	}
}

/**
 * Initialize the load of the sticky color content by cloning the original colored block.
 */
function initStickyColorContentLoad(stickyEl) {
	const clonedStickyHeader = stickyEl.cloneNode(true);

	clonedStickyHeader.classList.add('cloned');
	stickyEl.parentNode.insertBefore(clonedStickyHeader, stickyEl.nextSibling);
}

/**
 * Initializes events for the sticky color content.
 *
 * @param {Element} stickyEl - The frame element associated with the sticky content.
 * @param {Element} frameEl - The frame element for correct calculating intersection
 * @returns {void}
 */
function initStickyColorContentEvents(stickyEl, frameEl, mediaBlock) {
	const currentIntersectionObserver = getIntersectionObserver();
	const currentResizeObserver = getResizeObserver();

	currentIntersectionObserver.observe(frameEl);
	currentResizeObserver.observe(stickyEl);

	if (cache.parallaxContainer.length && !app.device.isMobileView()) {
		currentIntersectionObserver.observe(mediaBlock);
	}

	if (!isDesktop) {
		updateColorsOnViewportResize();
	}
}

/**
 * Updates colors of sticky containers based on the intersection
 * ratio with the viewport during viewport resize.
 */
function updateColorsOnViewportResize() {
	window.visualViewport.addEventListener('resize', (e) => {
		const stickyContainers = document.querySelectorAll(cache.stickyContainerSel);

		stickyContainers.forEach((container) => {
			const frameEl = container.querySelector(cache.stickyFrameSel);

			const intersectionRatio = calcExtendedIntersectionRatio(frameEl.getBoundingClientRect(), e.target);

			container.style.setProperty('--default-color-visible-percentage', `${100 - intersectionRatio}%`);
		});
	}, 50);
}

/**
 * Initializes a ResizeObserver to dynamically adjust the size of the frame based on the sticky element's height.
 *
 * @returns {ResizeObserver} - The ResizeObserver instance.
 */
function getResizeObserver() {
	if (resizeObserver === null) {
		resizeObserver = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				const currentFrame = entry.target.parentElement.previousElementSibling;

				currentFrame.style.height = `${entry.borderBoxSize[0].blockSize}px`;
			});
		});
	}

	return resizeObserver;
}

/**
 * Get the IntersectionObserver for changing color in sticky content block.
 * @returns {IntersectionObserver} - The IntersectionObserver instance.
 */
function getIntersectionObserver() {
	if (intersectionObserver === null) {
		intersectionObserver = new IntersectionObserver(scrollEvents, {
			root: null,
			rootMargin: '0% 0% 0% 0%',
			threshold: app.util.buildThresholdList(0, 100)
		});
	}

	return intersectionObserver;
}

function changeColor(entry) {
	let intersectionRatio = 0;

	const container = entry.target.closest(cache.stickyContainerSel);

	if (entry.isIntersecting) {
		if (!isDesktop) {
			intersectionRatio = calcExtendedIntersectionRatio(entry.boundingClientRect, window.visualViewport);
		} else {
			intersectionRatio = entry.intersectionRatio * 100;
		}
	}

	container.style.setProperty('--default-color-visible-percentage', `${100 - intersectionRatio}%`);
}

/**
 * Applies a parallax effect to an image inside the observed entry element based on scroll position.
 * The function calculates the translation for the image and adjusts it based on screen size and
 * the image's position relative to the parallax container.
 *
 * @param {IntersectionObserverEntry} entry - The IntersectionObserver entry object representing the observed element.
 */
function setParallaxEffect(entry) {
	const parallaxImage = entry.target.querySelector('img');
	const imageHeight = parallaxImage.getBoundingClientRect().height;

	if (entry.isIntersecting) {
		const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
		const scrollDifference = currentScrollTop - lastScrollTop;

		const isImageBottomEdgeShown = (entry.boundingClientRect.height + Math.abs(imgTranslateY)) >= imageHeight;
		const newImgTranslateY = imgTranslateY - scrollDifference;
		let parallaxImageOffset = 0;

		if (!isImageBottomEdgeShown && entry.intersectionRatio > parallaxSettings.defaultTopImageStaticThreshold) {
			parallaxImageOffset = newImgTranslateY;
		} else {
			parallaxImageOffset = entry.boundingClientRect.height - imageHeight;
		}

		parallaxImage.style.setProperty('--parallax-image-offset', `${parallaxImageOffset}px`);
		imgTranslateY = newImgTranslateY;
		lastScrollTop = currentScrollTop;
	}
}

/**
 * Callback function for the IntersectionObserver to change color (css clip-path: polygon) based on intersection.
 * @param {IntersectionObserverEntry[]} entries - Array of intersection observer entries.
 */
function scrollEvents(entries) {
	entries.forEach((entry) => {
		if (entry.target.classList.contains(cache.stickyFrameClass)) {
			changeColor(entry);
		} else if (entry.target.classList.contains(cache.parallaxContainerClass) && !app.device.isMobileView()) {
			setParallaxEffect(entry);
		}
	});
}

/**
 * Calculates the ratio of the intersected part of an element to its viewport in percentage,
 * taking in account addressBar animation on touch devices
 *
 * @param {DOMRect} elementBoundingClientRect - The DOMRect object providing the size and coordinates of the element.
 * @param {VisualViewport} viewport - An object representing information about the viewport.
 * @returns {number} - The ratio of the intersected area to the height of the element in percentage.
 */
function calcExtendedIntersectionRatio(elementBoundingClientRect, viewport) {
	const intersectionHeight = Math.max(0, Math.min(elementBoundingClientRect.bottom, viewport.height) - Math.max(elementBoundingClientRect.top, viewport.offsetTop));

	return (intersectionHeight / elementBoundingClientRect.height) * 100;
}

export default StickyColorContent;
