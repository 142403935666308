export default {
	singleItem: true,
	autoplayHoverPause: true,
	dots: true,
	autoplay: false,
	autoHeight: false,
	loop: true,
	mouseDrag: true,
	touchDrag: true
};
