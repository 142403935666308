import defaultConfig from '../../configs/widgets/slidingOwlDefaults';

class SlidingOwlMgr {
	initAll() {
		const slidingOwlWidgets = document.querySelectorAll('.js-owl_sliding');

		slidingOwlWidgets.forEach((slidingOwlWidget) => {
			this.init(slidingOwlWidget);
		});
	}

	init(slidingOwlWidget) {
		const type = slidingOwlWidget.dataset.carouselType;
		const deviceSpecifity = slidingOwlWidget.dataset.carouselDevice;
		const widgetsCarouselsConfig = app.configs?.carouselSettings?.owl?.widgets;
		const config = slidingOwlWidget.dataset.dataSettings || widgetsCarouselsConfig[type] || defaultConfig;

		if (isInitForCurrentDevice(deviceSpecifity)) {
			if (type !== 'products') {
				app.owlcarousel.initCarousel($(slidingOwlWidget), config);
			} else {
				document.addEventListener('price.loaded', function() {
					app.owlcarousel.initCarousel($(slidingOwlWidget), config);
					document.dispatchEvent(new CustomEvent('lazyload-reinit'));
				});
			}
		}
	}
}

const isInitForCurrentDevice = (device) => {
	switch(device) {
		case 'mobile':
			return app.device.isMobileUserAgent();
		case 'tablet':
			return app.device.isTabletUserAgent();
		case 'desktop':
			return !app.device.isMobileUserAgent() && !app.device.isTabletUserAgent();
		default:
			return true;
	}
};

export default new SlidingOwlMgr();
