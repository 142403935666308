import SlidingPills from './SlidingPills';

class ToggleSizeGuideUnits {
	constructor(containerSelector) {
		this.slidingPills = null;
		this.containerSelector = containerSelector || '.js-size_guide-tables_content';
		this.container = document.querySelector(this.containerSelector);
		this.defaultSizeUnit = (typeof app != 'undefined' && app.preferences.unitOfMeasures);
		this.tablesWithSizeUnits = [];

		this.init();
	}

	init() {
		this.tablesWithSizeUnits = [...this.container.querySelectorAll('.js-sizeunits-table')];

		if (this.container && this.tablesWithSizeUnits.length && !this.container.querySelector('.js-sliding-pills')) {
			const sizeUnitsArray = this.getSizeUnitsValues();

			this.slidingPills = new SlidingPills(sizeUnitsArray, this.defaultSizeUnit, this.container);

			this.slidingPills.subscribe(this);
			this.slidingPills.init();
		}
	}

	getSizeUnitsValues() {
		const sizeUnits = [];

		for (const table of this.tablesWithSizeUnits) {
			const unitName = table.dataset.unitsName;
			const unitId = table.dataset.unitsId;

			sizeUnits.push({ id: unitId, name: unitName || unitId });
		}

		return sizeUnits;
	}

	update(updateID, data) {
		switch (updateID) {
			case 'changeActivePills':
				this.changeActiveUnitTable(data.id);

				break;
			default:
				break;
		}
	}

	changeActiveUnitTable(activeUnitId) {
		for (const table of this.tablesWithSizeUnits) {
			if (table.dataset.unitsId === activeUnitId) {
				table.classList.add('active');
			} else {
				table.classList.remove('active');
			}
		}
	}
}

export default ToggleSizeGuideUnits;
