class FileList {
	/**
	 * Removes a file (by given index) from a file list.
	 * @param {FileList} files - The file list.
	 * @param {number} index - The index of the file to be removed from the file list.
	 * @returns {FileList} - The updated file list.
	 */
	removeFile(files, index) {
		const updatedFileList = new DataTransfer();

		for (let i = 0, len = files.length; i < len; i++) {
			if (index !== i) {
				updatedFileList.items.add(files[i]);
			}
		}

		return updatedFileList.files;
	}

	/**
	 * Adds new files to the existing file list.
	 * @param {FileList} files - The existing file list.
	 * @param {FileList} newFiles - The new files to add.
	 * @returns {FileList} - The updated file list.
	 */
	addFiles(files, newFiles) {
		const updatedFileList = new DataTransfer();

		for (let i = 0, len = files.length; i < len; i++) {
			updatedFileList.items.add(files[i]);
		}

		for (let i = 0, len = newFiles.length; i < len; i++) {
			updatedFileList.items.add(newFiles[i]);
		}

		return updatedFileList.files;
	}
}

export default new FileList();
