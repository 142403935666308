class ResponsiveVideoMgr {
	initAll() {
		const videos = document.querySelectorAll('.js-videomgr-responsive');

		videos.forEach((el) => {
			if (isElementVisible(el)) {
				this.init(el);
			}
		});
	}

	init(el) {
		if ('videoMgr' in app.components.global) {
			app.components.global.videoMgr.initElement($(el));
		}
	}
}

function isElementVisible(element) {
	return element.offsetWidth > 0 && element.offsetHeight > 0;
}

export default new ResponsiveVideoMgr();
