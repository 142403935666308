class PriceMgr {
	fetchPrices(pids, source) {
		const prices = {};
		const url = new URL(app.urls.getProductPrices);

		url.searchParams.set('pids', pids.join(','));
		url.searchParams.set('source', source);

		return fetch(url)
			.then((response) => response.json())
			.then(({ data }) => {
				Object.keys(data.products).forEach((pid) => {
					prices[pid] = data.products[pid];
				});

				return Promise.resolve(prices);
			})
			.catch(() => Promise.resolve({}));
	}
}

export default new PriceMgr();
