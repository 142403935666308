import config from './config';

class ServiceWorkerUtils {
	emptyCache() {
		this.postMessage({ type: config.worker.emptyCacheMessage });
		sessionStorage.removeItem(config.prevUrlPlp);
	}

	postMessage(message) {
		if (navigator.serviceWorker && navigator.serviceWorker.controller) {
			navigator.serviceWorker.controller.postMessage(message);
		}
	}
}

export default new ServiceWorkerUtils();
