import ModuleConstructorMgr from 'oneapp/src/managers/widgets/ModuleConstructorMgr';
import SlidingOwlMgr from './widgets/slidingOwlMgr';
import ResponsiveVideoMgr from './widgets/responsiveVideoMgr';

class WidgetsMgr {
	static init() {
		/** here we must init all others widgets */
		ModuleConstructorMgr.initAll(document.querySelectorAll('.js-module_constructor'));
		SlidingOwlMgr.initAll();
		ResponsiveVideoMgr.initAll();
	}
}

export default WidgetsMgr;
