import ModuleConstructor from '../../components/widgets/moduleConstructor/index';

class ModuleConstructorMgr {
	/**
	 * Initializes All ModuleConstructor Widgets
	 * @param {HTMLElement[]} moduleConstructorWidgets - Module Specific Block
	 */
	// eslint-disable-next-line no-restricted-syntax
	initAll(moduleConstructorWidgets) {
		// eslint-disable-next-line no-restricted-syntax
		for (const moduleConstructorWidget of moduleConstructorWidgets) {
			initModuleConstructor(moduleConstructorWidget);
		}
	}
}

/**
 * Initializes ModuleConstructor Widget
 * @param {HTMLElement} moduleConstructorWrapper - Module Grid
 */
function initModuleConstructor(moduleConstructorWrapper) {
	const config = {
		moduleConstructorWrapper: moduleConstructorWrapper
	};

	ModuleConstructor.build(config);
}

export default new ModuleConstructorMgr();
