/** Class representing a Quantity Selector class. */
export default class QuantitySelector {
	/** Create a Quantity Selector instance. */
	constructor(configuration = {}) {
		this.$increaseQuantityButton = configuration.$increaseQuantityButton;
		this.$decreaseQuantityButton = configuration.$decreaseQuantityButton;
		this.$quantityDisplayBlock = configuration.$quantityDisplayBlock;
		this.maximumQuantityLimitation = configuration.maximumQuantityLimitation || app.preferences.maxLineItemQty;
		this.minimumQuantityLimitation = configuration.minimumQuantityLimitation || 1;
		this.updateQuantityCallBack = configuration.updateQuantityCallBack;
		this.skipQuantityViewUpdating = configuration.skipQuantityViewUpdating;
		this.preventDefaultEvent = configuration.preventDefaultEvent || true;
		this.getQuantityValue();
	}

	/** Initialize Quantity Selector. */
	initializeQuantitySelector() {
		this.initializeButtons();
		this.initializeEvents();
	}

	/** Read and return the quantity value from quantity display block.
	 * @return {Number} quantity
	 */
	getQuantityValue() {
		this.quantityValue = parseInt(this.$quantityDisplayBlock.val());
		return this.quantityValue;
	}

	/** Change disabled property for item
	 * * @param {Object} item - JQuery item
	 * * @param {Boolean} value - value to set
	 */
	static changeDisabledProperty(item, value) {
		if (item.prop('disabled') !== value) {
			item.prop('disabled', value);
		}
	}

	/** Validate quantity and perform required actions
	 * * @param {Number} quantityValue - quantity value
	 */
	validateQuantity(quantityValue) {
		switch (quantityValue) {
			case this.minimumQuantityLimitation:
				this.constructor.changeDisabledProperty(this.$decreaseQuantityButton, true);
				break;
			case this.maximumQuantityLimitation:
				this.constructor.changeDisabledProperty(this.$increaseQuantityButton, true);
				break;
			default:
				this.constructor.changeDisabledProperty(this.$increaseQuantityButton, false);
				this.constructor.changeDisabledProperty(this.$decreaseQuantityButton, false);
		}
	}

	/** Update quantity display block value via quantity
	 * @param {Number} quantityValue - quantity value
	 */
	updateQuantityView(quantityValue) {
		this.$quantityDisplayBlock.val(quantityValue);
	}

	/** Processing quantity modification
	 * @param {Object} event - DOM Event
	 * @param {Function} quantityModificationFunction - quantity modification function
	 */
	processQuantityModification(event, quantityModificationFunction) {
		if (this.preventDefaultEvent) {
			event.preventDefault();
		}

		let quantityValue = this.getQuantityValue();
		quantityValue = quantityModificationFunction(quantityValue);

		this.validateQuantity(quantityValue);

		if (!this.skipQuantityViewUpdating) {
			this.updateQuantityView(quantityValue);
		}

		this.updateQuantityCallBack(quantityValue);
	}

	/** Initialize events */
	initializeEvents() {
		this.$increaseQuantityButton.on('click', (e) => this.processQuantityModification(e, (qty) => ++qty));
		this.$decreaseQuantityButton.on('click', (e) => this.processQuantityModification(e, (qty) => --qty));
	}

	/** Initialize buttons */
	initializeButtons() {
		this.validateQuantity(this.quantityValue);
	}
}
