import HoverClassEffect from './effects/HoverClass';
import StretchOnScrollEffect from './effects/StretchOnScroll';
import StickyColorContentEffect from './effects/StickyColorContent';

const LAZY_LOADING_OFFSET = 100;

/**
 * Represents ModuleConstructor
 */
class ModuleConstructor {
	/**
	 * Builds Module
	 */
	static build(config) {
		initHoverEffect.call(this, config);
		initImgLazyLoading.call(this, config);
		initStretchOnScrollEffect.call(this, config);

		if (config.moduleConstructorWrapper.classList.contains('js-sticky_container')) {
			initStickyColorContent.call(this, config);
		}
	}
}

/**
 * Initializes elements hover effect
 * @param {Object} config - Module config
 * @param {HTMLElement} config.moduleConstructorWrapper - Module Specific Block
 */
function initHoverEffect(config) {
	const hoverElements = config.moduleConstructorWrapper.querySelectorAll('.js-hover-element');

	for (const hoverElement of hoverElements) {
		new HoverClassEffect({
			hoverElement: hoverElement,
			moduleConstructorWrapper: config.moduleConstructorWrapper
		});
	}
}

/**
 * Initializes Lazy Loading
 * @param {Object} config - Module config
 * @param {HTMLElement} config.moduleConstructorWrapper - Module Specific Block
 */
function initImgLazyLoading(config) {
	const images = config.moduleConstructorWrapper.querySelectorAll('[data-src]');
	const lazyConfig = {
		rootMargin: `${LAZY_LOADING_OFFSET}px 0px 0px 0px`,
		threshold: 0
	};

	const observer = new IntersectionObserver(function(entries, self) {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				preloadImage(entry.target);
				// Stop watching and load the image
				self.unobserve(entry.target);
			}
		});
	}, lazyConfig);

	images.forEach((image) => {
		observer.observe(image);
	});

	function preloadImage(img) {
		const src = img.getAttribute('data-src');

		if (!src) {
			return;
		}

		img.src = src;
	}
}

/**
 * Initializes scaling image on page scroll
 * @param {Object} config - Module config. Contains moduleConstructorWrapper - current widget block
 * @param {HTMLElement} config.moduleConstructorWrapper - Module Specific Block
 */
function initStretchOnScrollEffect(config) {
	const stretchOnScrollElements = config.moduleConstructorWrapper.querySelectorAll('.js-stretchOnScroll-element');

	for (const stretchOnScrollElement of stretchOnScrollElements) {
		new StretchOnScrollEffect({
			moduleConstructorWrapper: config.moduleConstructorWrapper
		});
	}
}

/**
 * Initializes scaling image on page scroll
 * @param {Object} config - Module config. Contains moduleConstructorWrapper - current widget block
 * @param {HTMLElement} config.moduleConstructorWrapper - Module Specific Block
 */
function initStickyColorContent(config) {
	const stickyColorBlocks = config.moduleConstructorWrapper.querySelectorAll('.js-sticky_header');

	for (const stickyColorBlock of stickyColorBlocks) {
		new StickyColorContentEffect({
			stickyColorBlock: stickyColorBlock,
			moduleConstructorWrapper: config.moduleConstructorWrapper
		});
	}
}

export default ModuleConstructor;
