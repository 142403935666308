const trackerData = (app && app.trackerData) || {};
const $document = $(document);
const PRODUCT_VARIANTS = '.js-gtm_product_variants_info';

function onAddToCart() {
	$document.on('tracking.cartItemAdded', function() {
		if (trackerData.cartInfo && trackerData.cartInfo.cartProducts) {
			const lastItemIndex = trackerData.cartInfo.cartProducts.length - 1;
			const trackerDataProduct = trackerData.cartInfo.cartProducts[lastItemIndex];
			const productObj = {
				productMasterID: trackerDataProduct.masterId,
				productName: trackerDataProduct.name,
				productPrice: trackerDataProduct.price,
				productBrand: trackerDataProduct.brand,
				productSubcategory: trackerDataProduct.category,
				productPrimaryCategory: trackerDataProduct.primaryCategory,
				productColor: trackerDataProduct.color,
				productDimension9: trackerDataProduct.dimension9,
				productDimension10: trackerDataProduct.dimension10,
				productDimension50: trackerDataProduct.dimension50,
				quantity: trackerDataProduct.quantity,
				trackingOrigin: trackerData.productClickOrigin
			};

			addToCart(productObj);
		}
	});
}

/**
 * @private
 */
function addToCart(productObj) {
	const addToBagData = {
		event: 'addToBag',
		addToBagType: 'Product page',
		bagAmount: (parseFloat(productObj.productPrice || 0) * parseInt(productObj.quantity, 10)).toFixed(2),
		productName: productObj.productName,
		productSubcategory: productObj.productSubcategory,
		productPrice: productObj.productPrice
	};

	const addToCartData = {
		event: 'addToCart',
		ecommerce: {
			currencyCode: trackerData.currencyCode,
			add: {
				actionField: {
					list: productObj.trackingOrigin
				},
				products: [{
					id: productObj.productMasterID,
					quantity: parseInt(productObj.quantity),
					name: productObj.productName,
					price: productObj.productPrice,
					brand: productObj.productBrand,
					category: productObj.productPrimaryCategory,
					variant: productObj.productColor,
					dimension9: productObj.productDimension9,
					dimension10: productObj.productDimension10,
					dimension50: productObj.productDimension50
				}]
			}
		}
	};

	const activeTabId = localStorage.getItem('activetabid');

	if (activeTabId) {
		addToCartData.ecommerce.activeTabId = activeTabId;
		addToBagData.activeTabId = activeTabId;
	}

	fireEvent('add_to_cart_cust', addToBagData);
	fireEvent('add_to_cart_cust', addToCartData);
}

function onProductTileClick() {
	$document.on('click', '.js-product_tile a, .js-tile-details', function(event) {
		let selector = '.js-product_tile';
		let $tile = $(event.target).closest(selector);
		let eventData = {};

		if ($tile.length === 0) {
			selector = '.js-product';
			$tile = $(event.target).closest(selector);
		}

		const origin = getTileOrigin($tile);

		if (origin) {
			$.cookie('productClickOrigin', origin, { path: '/' });
		}

		const pid = $tile.data('itemid') || $tile.data('pid');

		if ($tile.data('product-name') && pid) {
			eventData = {
				event: 'productClick',
				productOriginalPrice: $tile.data('originalprice'),
				productPrice: $tile.data('price'),
				ecommerce: {
					click: {
						actionField: {
							list: origin
						},
						products: [{
							name: typeof $tile.data('product-name') !== 'undefined' ? $tile.data('product-name') + '' : '', // custom.title attribute in default locale
							id: pid,
							price: $tile.data('price'),
							brand: $tile.data('brand'),
							category: $tile.data('category'),
							variant: $tile.data('variant'), // custom.colorDescription field in default locale
							position: +$(selector).index($tile) + 1,
							dimension9: $tile.data('dimension9'),
							dimension10: $tile.data('dimension10')
						}]
					}
				}
			};

			fireEvent('select_item', eventData);
		}
	});
}

function getTileOrigin($target) {
	let origin;

	if ($target.closest('.js-search_result-content').length) {
		origin = app.constants.CURRENT_CATEGORY_ID ? app.constants.CURRENT_CATEGORY_ID : null;
	} else if ($target.closest('.js-last-visited-wrapper').length) {
		origin = 'recently-viewed';
	} else if ($target.closest('.js-you-may-also-like').length) {
		origin = 'you-may-also-like';
	} else if ($target.closest('.js-style-it-with').length) {
		origin = 'wear-this-with';
	} else if ($target.closest('.js-shop-the-look-component').length) {
		origin = 'shop-the-look';
	} else if ($target.closest('.js-product-color-variations-wrapper').length) {
		origin = 'more-colors';
	} else { // fallback for recommendations block on no search result page, empty cart, empty wishlist etc
		origin = 'you-may-also-like';
	}

	return origin;
}

// product pages & Quick shop. Click on add to wishlist.
function addToWishlist() {
	$document.on('click', '.carttable .addtowishlist', function() {
		const m = /pid\=([\d\w]+)/ig.exec($(this).prop('href'));

		if (m && m[1]) {
			$.each(trackerData.products, function(i, k) {
				if (m[1] === k.productSku) {
					fireEvent('add_to_wishlist', {
						event: 'wishlistAdd',
						productName: k.productName || '',
						productPrice: k.productPrice || 0
					});
				}
			});
		}
	});

	$document.on('wishlist.added', function() {
		const product = getCurrentProduct($('.js-product_number > span').html());

		fireEvent('add_to_wishlist', {
			event: 'wishlistAdd',
			productName: product.productName || '',
			productPrice: product.productPrice || 0
		});
	});
}

/**
 * @private
 */
function getCurrentProduct(id) {
	const $pPopUpInfo = $('.js-pdp_main').find(PRODUCT_VARIANTS);
	const $pProductInfo = $('head').children(PRODUCT_VARIANTS);
	let $pWishListInfo;
	let json = '';
	let product;

	id = id || 'default';

	if ($pPopUpInfo.length) {
		json = JSON.parse($pPopUpInfo.html());
		$.each(json, function(i, k) {
			if (id === k.productSku) {
				product = k;
			}
		});
	} else if ($pProductInfo.length) {
		json = JSON.parse($pProductInfo.html());
		$.each(json, function(i, k) {
			if (id === k.productSku) {
				product = k;
			}
		});
	} else if (trackerData.wishlist) {
		$.each(trackerData.wishlist, function(i, k) {
			if (id === k.inID) {
				product = k;
			}
		});
	}

	if (!product) {
		$pWishListInfo = $('.js-header_wishlist_tracker_data');

		if ($pWishListInfo.length) {
			json = JSON.parse($pWishListInfo.html());
			$.each(json, function(i, k) {
				if (id === k.inID) {
					product = k;
				}
			});
		}
	}

	return product || trackerData;
}

function onRemoveFormCart() {
	$("button[name$='_deleteProduct']").on('click', function() {
		const id = /\_i(\d+?)\_deleteProduct$/ig.exec($(this).attr('name'));

		if (trackerData?.ecommerce?.impressions?.[id[1]]) {
			const product = trackerData.products[id[1]];

			fireEvent('remove_from_cart', {
				event: 'removeFromCart',
				ecommerce: {
					remove: {
						products: [{
							id: product.productMasterID,
							quantity: trackerData.ecommerce.impressions[id[1]].quantity,
							name: product.productName,
							price: product.productPrice,
							brand: product.productBrand,
							category: product.productSubcategory,
							variant: product.productColor,
							dimension50: product.productVariationID
						}]
					}
				}
			});
		}
	});
}

function registerUser() {
	if (
		window.location.href.includes('registration=true')
		&& trackerData.userInfo
		&& trackerData.userInfo.accountCustomerId
	) {
		fireEvent('sign_up', {
			event: 'accountCreation',
			accountCustomerId: trackerData.userInfo.accountCustomerId,
			visitorGender: trackerData.userInfo.gender,
			accountType: trackerData.accountType,
			newsletterSubsription: trackerData.userInfo.newsletterSubscription
		});
	}
}

function fireEvent(name, data) {
	if (
		window.tC,
		window.tC.event,
		name in window.tC.event
	) {
		window.tC.event[name](this, data);
	}
}

function onSearch() {
	document.addEventListener('searchResult.loaded', function(e) {
		fireEvent('search', {search_term: e.detail});
	});
}
function onLogin() {
	document.addEventListener('login.success', function() {
		fireEvent('login', {});
	})
}

export default {
	onAddToCart,
	onProductTileClick,
	addToWishlist,
	onRemoveFormCart,
	registerUser,
	onSearch,
	onLogin
};
