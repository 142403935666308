const HOVER_DELAY = 100;

class HoverClassEffect {
	constructor(config) {
		this.moduleConstructorWrapper = config.moduleConstructorWrapper;
		this.hoverElement = config.hoverElement;

		this.hoverClass = this.hoverElement.dataset.hoverTargetClass;
		this.parentItemEl = this.hoverElement.closest('.js-module_constructor-item');
		this.timerId = null;

		const targetElementSel = this.hoverElement.dataset.targetElementSel;

		this.targetEl = targetElementSel ? this.hoverElement.closest(targetElementSel) : this.moduleConstructorWrapper;

		initHoverClassEvents.call(this);
	}
}

function initHoverClassEvents() {
	this.hoverElement.addEventListener('mouseenter', mouseEnterHandler.bind(this));
	this.hoverElement.addEventListener('mouseleave', mouseLeaveHandler.bind(this));
}

function mouseEnterHandler() {
	this.timerId = setTimeout(() => {
		this.parentItemEl.classList.add('m-hovered-item');
		this.targetEl.classList.add(this.hoverClass);
	}, HOVER_DELAY);
}

function mouseLeaveHandler() {
	clearTimeout(this.timerId);

	setTimeout(() => {
		this.parentItemEl.classList.remove('m-hovered-item');
		this.targetEl.classList.remove(this.hoverClass);
	}, HOVER_DELAY);
}

export default HoverClassEffect;
