import 'core-js';
import PriceMgr from 'oneapp/src/classes/price/PriceMgr';
import DOM from 'oneapp/src/utils/DOM';
import PriceBuilder from '../classes/price/Builder';
import SFMCConversionTracking from '../classes/sfmc/SFMCConversionTracking';

class Page {
	constructor(id) {
		this.id = id;
	}

	init() {
		$(document).on('ready recommendations.loaded wishlist.uploaded price-reinit', () => {
			this.loadPrices();
		});

		if (SFMCConversionTracking.isEnabled()) {
			SFMCConversionTracking.storeParameters();
		}
	}

	loadPrices() {
		const prices = Array.from(document.querySelectorAll('.js-product_tile:not(.js-price_loaded)'));
		const priceBuilder = new PriceBuilder('#productTilePrice');

		if (!prices.length) {
			return;
		}

		const pids = prices.map((price) => price.dataset.itemid);

		PriceMgr.fetchPrices(pids, this.id)
			.then((returnedPrices) => {
				prices.forEach((price) => {
					const pid = price.dataset.itemid;

					if (returnedPrices[pid]) {
						const priceBlock = priceBuilder.build(returnedPrices[pid]);

						DOM.replaceWith(price.querySelector('.js-product_min_price'), priceBlock);
						price.classList.add('js-price_loaded');
					}
				});

				document.dispatchEvent(new CustomEvent('price.loaded'));
			});
	}
}

export default Page;
