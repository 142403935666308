import Cookies from 'js-cookie';

const sfmcParameters = ['j', 'sfmc_sub', 'l', 'u', 'mid', 'jb'];

/**
 * Represents SFMCConversionTracking
 * @constructor
 */
class SFMCConversionTracking {
	constructor() {}

	storeParameters() {
		const urlParameters = new URLSearchParams(window.location.search);

		for (const parameterName of sfmcParameters) {
			const parameterValue = urlParameters.get(parameterName);

			if (parameterValue) {
				Cookies.set(`sfmc_${parameterName}`, parameterValue);
			}
		}
	}

	clearCookies() {
		for (const parameterName of sfmcParameters) {
			Cookies.remove(`sfmc_${parameterName}`);
		}
	}

	appendImage() {
		const image = document.createElement('img');

		image.setAttribute('src', `http://${app.preferences.SFMCConversionTrackingHost}/conversion.aspx?xml=${buildXML()}`);
		image.setAttribute('height', '1');
		image.setAttribute('width', '1');

		document.getElementsByClassName('js-checkout_order_summary')[0].appendChild(image);

		this.clearCookies();

		function buildXML() {
			return `
				<system>
					<system_name>tracking</system_name>
					<action>conversion</action>
					<member_id>${Cookies.get('sfmc_mid')}</member_id>
					<job_id>${Cookies.get('sfmc_j')}</job_id>
					<email></email>
					<sub_id>${Cookies.get('sfmc_sfmc_sub')}</sub_id>
					<list>${Cookies.get('sfmc_l')}</list>
					<original_link_id>${Cookies.get('sfmc_u')}</original_link_id>
					<BatchID>${Cookies.get('sfmc_jb')}</BatchID>
					<conversion_link_id>1</conversion_link_id>
					<link_alias>Order confirmation page</link_alias>
					<display_order>1</display_order>
					<data_set>
						<data amt=\"${app.trackerData.transactionTotal.split('.')[0]}\" unit=\"${app.trackerData.transactionCurrency}\" accumulate=\"true\"/>
					</data_set>
				</system>
			`.replace(/[\r\n\t]+/g, '');
		}
	}

	isEnabled() {
		return app.preferences.enableSFMCConversionTracking;
	}
}

export default new SFMCConversionTracking();
