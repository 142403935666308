import 'core-js';
import PageBase from 'oneapp/src/pages/page';
import ContactFormAction from 'oneapp/src/components/ContactFormAction';
import TagCommanderMgr from '../managers/tagcommanderMgr';

class Page extends PageBase {
	init() {
		super.init();

		triggerFirstLoginEvent();

		for (const form of document.querySelectorAll('.js-contactForm')) {
			new ContactFormAction(form);
		}
	}
}

function triggerFirstLoginEvent() {
	document.addEventListener('DOMContentLoaded', () => {
		const isFirstLogin = sessionStorage.getItem('firstLogin') === 'true';
		const trackerData = (app && app.trackerData) || {};
		const userLoggedIn = trackerData.userInfo.loggedIn === 'Logged In';

		if (userLoggedIn) {
			if (isFirstLogin) {
				sessionStorage.setItem('firstLogin', false);
				document.dispatchEvent(new Event('login.success'));
			}
		} else {
			sessionStorage.setItem('firstLogin', true);
		}
	});
}

export default Page;
