/* eslint-disable class-methods-use-this */

/**
 * Methods of this class are recognized and applied by {@link TemplateEngine#render}.
 */
class TemplateFunctions {
	href(url) {
		return `href="${url || ''}"`;
	}

	src(url) {
		return `src="${url || ''}"`;
	}
}

export default new TemplateFunctions();
