class Observable {
	constructor() {
		this.listeners = [];
	}

	subscribe(listener) {
		if (typeof listener.update === 'function') {
			this.listeners.push(listener);
		}
	}

	unsubscribe(listenerToRemove) {
		this.listeners = this.listeners.filter((listener) => listener !== listenerToRemove);
	}

	unsubscribeAll() {
		this.listeners = [];
	}

	notify(updateID, data) {
		for (const listener of this.listeners) {
			listener.update(updateID, data);
		}
	}
}

export default Observable;
