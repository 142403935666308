import FileListUtils from 'oneapp/src/utils/FileList';

const ATTACHMENT_NAME = 'attachment'

export default class ContactFormAction {
	constructor(form) {
		this.form = form;
		this.files = [];

		initEvents.call(this)
	}

	handlerContactFormAction() {
		const formData = new FormData(this.form);
		const url = new URL(this.form.action);
		let fileCount = 0;

		for (const file of this.files) {
			fileCount++;
			formData.set(`${ATTACHMENT_NAME}${fileCount}`, file, file.name);
		}

		formData.delete(ATTACHMENT_NAME);

		fetch(url.href, {
			method: 'POST',
			body: formData
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.success) {
					app.popupsMgr.open('RequestFormPopup');
					setTimeout(() => {
						window.location.reload();
					}, 3000);
				}
			});
	}
}

/**
 * Initializes event listeners for the form, including submit button and attachment input.
 */
function initEvents() {
	const submitButtonNode = this.form.querySelector('[type="submit"]');
	const attachmentInputNode = this.form.querySelector('.js-attachment-input');

	submitButtonNode?.addEventListener('click', (e) => {
		e.preventDefault();

		if ($(this.form).valid()) {
			this.handlerContactFormAction();
		}
	})

	attachmentInputNode?.addEventListener('change', () => {
		this.files = FileListUtils.addFiles(this.files, attachmentInputNode.files);
		attachmentInputNode.value = '';

		processFilesList.call(this);
	})
}

/**
 * Processes the list of files in an HTMLInputElement node.
 * @param {HTMLInputElement} node - The DOM node containing the file list.
 */
function processFilesList() {
	const attachmentFilesNode = this.form.querySelector('.js-attachment-files');
	const attachmentNoFilesNode = this.form.querySelector('.js-attachment-nofiles');

	if (!this.files?.length) {
		attachmentNoFilesNode.classList.remove('h-hidden');
		attachmentFilesNode.innerHTML = '';

		return;
	}

	attachmentNoFilesNode.classList.add('h-hidden');

	let fileMarkup = '';
	let fileCount = 0;

	for (const file of this.files) {
		fileMarkup += app.util.renderTemplate(
			document.getElementById('fileItem').innerHTML,
			{ name: file.name, index: fileCount }
		);
		fileCount++;
	}

	attachmentFilesNode.innerHTML = fileMarkup;

	const removeButtons = this.form.querySelectorAll('.js-attachment-remove');

	for (const removeButton of removeButtons) {
		removeButton.addEventListener('click', (e) => {
			e.preventDefault();

			const parentNode = removeButton.parentNode;

			this.files = FileListUtils.removeFile(this.files, +parentNode.dataset.index)

			processFilesList.call(this);
		});
	}
}
