import events from './tagcommanderEvent';

class TagCommanderMgr {
	constructor() {
		window.tc_vars = createTcVars(window.dataLayer || []);

		document.addEventListener('DOMContentLoaded', () => {
			initPageEvents();
		});
	}
}

function initPageEvents() {
	const pageTypes = getCurrentPageTypes();
	const pageEvents = getPageEvents(pageTypes);

	pageEvents.forEach((initEventFunction) => (initEventFunction()));
}

function getCurrentPageTypes() {
	const pageTypes = ['default'];

	if (app.trackerData?.section && app.trackerData.section !== 'default') {
		pageTypes.push(app.trackerData.section);
	}

	return pageTypes;
}

function getPageEvents(pageTypes) {
	const pageEventsMapping = {
		default: [
			events.onAddToCart,
			events.onProductTileClick,
			events.addToWishlist,
			events.onSearch,
			events.onLogin
		],
		checkout_1: [
			events.onRemoveFormCart
		],
		checkout_2: [
			events.onRemoveFormCart
		],
		myaccount: [
			events.registerUser
		]
	};
	let pageEvents = [];

	pageTypes.forEach((pageType) => {
		if (pageType in pageEventsMapping) {
			pageEvents = pageEvents.concat(pageEventsMapping[pageType]);
		}
	});

	return pageEvents;
}

function createTcVars(dataLayer) {
	const tc_vars = {};

	for (let i = 0; i < dataLayer.length; i++) {
		const event = dataLayer[i];

		for (const key in event) {
			if (event.hasOwnProperty(key)) {
				tc_vars[key] = event[key];
			}
		}
	}

	populateChannel(tc_vars);

	if (app.trackerData?.section && app.trackerData.section === 'plp') {
		const catID = app.trackerData.isCategorySearch ? app.trackerData.catID : 'search-results';
		const selector = '.js-search_result-content .js-product_tile';

		const impressions = getImpressionsBySelector(selector, catID);

		if (impressions.length) {
			tc_vars.event = 'productListLoad';
			tc_vars.ecommerce = {
				'currencyCode': app.trackerData.currencyCode,
				'impressions': impressions
			}

			const activeTabId = localStorage.getItem('activetabid');

			if (activeTabId) {
				tc_vars.ecommerce.activeTabId = activeTabId;
			}
		}
	}

	return tc_vars;
}

function populateChannel(tc_vars) {
	if (app.device.isTabletUserAgent()) {
		tc_vars.channel = 't';
	} else if (app.device.isMobileView()) {
		tc_vars.channel = 'm';
	} else {
		tc_vars.channel = 'd';
	}
}

function getImpressionsBySelector(selector, catID) {
	const $tiles = $(selector);
	const impressions = [];

	for (let i = 0, l = $tiles.length; i < l; i++) {
		const $tile = $($tiles[i]);

		impressions.push({
			name: $tile.data('product-name') || '', // custom.title attribute in default locale
			id: $tile.data('itemid') || '',
			price: $tile.data('price'),
			brand: $tile.data('brand'),
			category: $tile.data('category'),
			variant: $tile.data('variant'), // custom.colorDescription field in default locale
			list: catID,
			position: i + 1
		});
	}

	return impressions;
}

export default new TagCommanderMgr();
