class Builder {
	constructor(templateSelector) {
		const template = document.querySelector(templateSelector);

		this.priceHtmlBlock = template ? template.innerHTML : '';
	}

	build(price) {
		const salesPriceValid = price.salesPrices.length && price.salesPrices[0] && price.salesPrices[0].value;
		const discountPercent = parseInt(price.discountPercent);
		const standardPrice = price.rangePrices ? price.rangePrices.formattedPrice : price.listPrices[0].formattedPrice;

		return app.util.renderTemplate(
			this.priceHtmlBlock,
			{
				standardPrice,
				salesPrice: salesPriceValid ? price.salesPrices[0].formattedPrice : '',
				discountPercent: discountPercent || '',
				standardPriceClass: salesPriceValid && discountPercent ? 'js-product_price-standard b-product_price-standard--line_through' : '',
				discountPercentClass: (!salesPriceValid || !discountPercent) ? 'h-hidden' : '',
				salesPriceClass: (!salesPriceValid || !discountPercent) ? 'h-hidden' : ''
			}
		);
	}
}

export default Builder;
