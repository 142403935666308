/**
 * Create Form Field markups
 */
class FormFieldUtils {
	constructor() {
		this.errorBlockPrefix = 'error';

		this.classes = {
			errorMessageBlock: 'f-error_message-block',
			errorText: 'f-error_text',
			fieldWrapper: 'f-field',
			fieldValid: 'f-state-valid'
		};
	}

	setFormFieldValue(formField, value) {
		if (formField) {
			switch (formField.type) {
				case 'checkbox': {
					formField.checked = value || false;

					break;
				}
				case 'select': {
					const currentSelectedStep = formField.options.find((option) => option.selected);

					if (currentSelectedStep) {
						currentSelectedStep.selected = false;
					}

					const newSelectedOption = formField.options.find((option) => option.value === value);

					if (newSelectedOption) {
						newSelectedOption.selected = true;
					}

					break;
				}
				default: {
					formField.value = value || '';

					break;
				}
			}
		}
	}

	setErrorMessage(htmlName, errorMessage) {
		const errorMessageWrapperId = `${this.errorBlockPrefix}_${htmlName}`;
		const errorMessageWrapper = document.getElementById(errorMessageWrapperId);

		if (errorMessageWrapper) {
			const fieldWrapper = errorMessageWrapper.closest(`.${this.classes.fieldWrapper}`);

			fieldWrapper.classList.remove(this.classes.fieldValid);

			for (const errorMessageBlock of Array.from(
				errorMessageWrapper.getElementsByClassName(this.classes.errorMessageBlock)
			)) {
				const errorMessageTexts = Array.from(errorMessageBlock.getElementsByClassName(this.classes.errorText));

				if (errorMessageTexts.length) {
					for (const errorMessageText of errorMessageTexts) {
						errorMessageText.innerHTML = errorMessage;
					}
				} else {
					errorMessageBlock.appendChild(this.getErrorTextBlock(htmlName, errorMessage));
				}
			}
		}
	}

	getErrorTextBlock(htmlName, errorMessage) {
		const errorTextSpan = document.createElement('span');
		errorTextSpan.innerHTML = errorMessage;
		errorTextSpan.for = htmlName;
		errorTextSpan.className = this.classes.errorText;
		errorTextSpan.ariaLive = 'assertive';

		return errorTextSpan;
	}

	appendHiddenField(targetElement, data) {
		const hiddenInput = document.createElement('input');

		hiddenInput.type = 'hidden';
		hiddenInput.name = data.name;
		hiddenInput.value = data.value;

		targetElement.appendChild(hiddenInput);
	}
}

export default FormFieldUtils;
