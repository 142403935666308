export default class EgonBase {
	init() {
		this.initializeCache();
		this.initializeEvents();
	}

	/**
	 * Initializes cache
	 */
	initializeCache() {
		this.hiddenClass = 'h-hidden';
		this.actionBlockerClass = 'h-click-disable';
		this.egonTriggerBlockClass = 'js-egon-trigger-block';
		this.egonReplaceableBlockClass = 'js-egon-replaceable-block';
		this.egonAddressSuggestionBlockClass = 'js-egon-address-suggestion-block';
		this.egonOptionClass = 'js-egon-option';
		this.resetObj = this.getResetObj();
		this.egonTriggerFields = document.querySelectorAll(`.${this.egonTriggerBlockClass} input, .${this.egonTriggerBlockClass} select`);
		this.addressSuggestionFields = document.querySelectorAll('select[name$=_addressSuggestion]');
	}

	/**
	 * Initializes events
	 */
	initializeEvents() {
		// handles egon trigger fields change
		this.egonTriggerFields.forEach(node => node.addEventListener('input', this.handleEgonTriggerFieldChange.bind(this)));
		// handles "addressSuggestion" fields change
		this.addressSuggestionFields.forEach(node => node.addEventListener('change', this.handleAddressSuggestionFieldChange.bind(this)));
	}

	/**
	 * Handles egon trigger fields change
	 * @param {Object} event
	 */
	handleEgonTriggerFieldChange(event) {
		const addressType = this.getAddressType(event.target.name);
		const countryCode = this[addressType].country?.value || this[addressType].editCountry;

		// clean up previously added/autocompleted values
		this.updateAutocompleteFields(addressType, this.resetObj);

		// if entered value is not valid, skip the following logic
		if (!app.validator.validateZipByCountry(countryCode, event.target.value)) {
			return;
		}

		// make a call to get address suggestions
		fetch(app.util.appendParamsToUrl(app.urls.getAddressSuggestions, {
			postalCode: event.target.value,
			languageCode: app.validator.detectLanguage(),
			countryCode
		}))
			.then(response => response.json())
			.then(response => this.updateAddressSuggestions(addressType, response.addressSuggestions));
	}

	/**
	 * Handles "addressSuggestion" field change
	 * @param {Object} event
	 */
	handleAddressSuggestionFieldChange(event) {
		const addressType = this.getAddressType(event.target.name);
		let addressSuggestion = {};

		try {
			addressSuggestion = JSON.parse(event.target.value);
		} catch (e) {}

		this.updateAutocompleteFields(addressType, addressSuggestion);
	}

	/**
	 * Returns AddressSuggestion reset obj
	 * @returns {object}
	 */
	getResetObj() {
		const result = {};

		Object.keys(app.preferences.EgonAddressSuggestionConfig).forEach((field) => {
			result[field] = { ...app.preferences.EgonAddressSuggestionConfig[field] };
			result[field].disabled = false;
		});

		return result;
	}

	/**
	 * Updates addressSuggestion select
	 * @param {string} addressType
	 * @param {Array} addressSuggestions
	 */
	updateAddressSuggestions(addressType, addressSuggestions = []) {
		// remove available "addressSuggestion" options except first
		this[addressType].addressSuggestion.options.length = 1;

		if (addressType && addressSuggestions.length) {
			// append new options
			addressSuggestions.forEach(addressSuggestion => {
				this.createOption(
					this[addressType].addressSuggestion,
					JSON.stringify(addressSuggestion),
					addressSuggestion.label
				);
			});

			// show "addressSuggestion" block and temporarily hide "replaceableBlock" block
			this[addressType].addressSuggestionBlock.classList.remove(this.hiddenClass);
			this[addressType].replaceableBlock.classList.add(this.hiddenClass);
			this.toggleAddressSuggestionMandatory(addressType, true);
		} else {
			// if there are no address suggestions, reset "addressSuggestion" field to not use previously set value
			this.createOption(this[addressType].addressSuggestion, '{}');
			this[addressType].addressSuggestion.value = '{}';
		}
	}

	/**
	 * Updates autocomplete fields using addressSuggestion data
	 * @param {string} addressType
	 * @param {Object} addressSuggestion
	 */
	updateAutocompleteFields(addressType, addressSuggestion = {}) {
		if (!addressType) {
			return;
		}

		// use data contained in the "addressSuggestion" to autocomplete the appropriate fields
		Object.keys(addressSuggestion).forEach((key) => {
			const field = this[addressType][key];

			if (!field) {
				return;
			}

			// check field type: text or select
			if (field.type === 'text') {
				this.updateInput(field, addressSuggestion[key]);
			} else {
				this.updateSelect(field, addressSuggestion[key]);
			}
		});

		// hide "addressSuggestion" block and show again "replaceableBlock" block
		this[addressType].replaceableBlock.classList.remove(this.hiddenClass);
		this[addressType].addressSuggestionBlock.classList.add(this.hiddenClass);
	}

	/**
	 * Toggles field mandatory attrs
	 * @param {string} addressType
	 * @param {boolean} mandatory
	 */
	toggleAddressSuggestionMandatory(addressType, mandatory) {
		if (!addressType) {
			return;
		}

		this[addressType].addressSuggestion.classList.toggle('f-state-required', mandatory);
		this[addressType].addressSuggestion.closest('.f-field').classList.toggle('f-state-required', mandatory);
		this[addressType].addressSuggestion.toggleAttribute('required', mandatory);
		this[addressType].addressSuggestion.toggleAttribute('aria-required', mandatory);
	}

	/**
	 * Creates <option>
	 * @param {Object} field
	 * @param {string} value
	 * @param {string} text
	 */
	createOption(field, value = '', text = '') {
		if (!field) {
			return;
		}

		const option = document.createElement('option');

		option.value = value;
		option.text = text;
		field.appendChild(option);
	}

	/**
	 * Updates input params
	 * @param {Object} field
	 * @param {Object} data
	 */
	updateInput(field, data = {}) {
		if (!field) {
			return;
		}

		field.value = data.value;
		field.classList.toggle(this.actionBlockerClass, Boolean(data.disabled));

		if (data.value) {
			$(field).valid();
		}
	}

	/**
	 * Updates select params
	 * @param {Object} field
	 * @param {Object} data
	 */
	updateSelect(field, data = {}) {
		if (!field) {
			return;
		}

		// find and remove previously added Egon option
		field.querySelector(`.${this.egonOptionClass}`)?.remove();

		// append new option if available
		if (data.value) {
			const option = document.createElement('option');

			option.text = data.value;
			option.value = data.value;
			option.classList.add(this.egonOptionClass);
			field.appendChild(option);
		}

		field.value = data.value;
		field.classList.toggle(this.actionBlockerClass, Boolean(data.disabled));

		if (data.value) {
			$(field).valid();
		}
	}
}
