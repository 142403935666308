import Observable from 'oneapp/src/classes/observable';

const ANIMATION_SPEED = 350;

class SlidingPills extends Observable {
	constructor(values, activeId, targetContainer, theme) {
		super();

		this.values = values;
		this.targetContainer = targetContainer;
		this.defaultActivePillId = activeId;
		this.activePill = null;
		this.theme = theme || 'tabs';
	}

	init() {
		const pillsWidget = this.buildPillsWidget();

		this.insertPillsInDOM(pillsWidget);

		this.activePill = this.targetContainer.querySelector(`[data-id="${this.defaultActivePillId}"]`);

		this.activatePill(this.activePill);
	}

	hide() {
		const slidingPillsHeadings = this.targetContainer.querySelector('.js-sliding-pills');
		slidingPillsHeadings.classList.add('h-hidden');
	}

	show() {
		const slidingPillsHeadings = this.targetContainer.querySelector('.js-sliding-pills');
		slidingPillsHeadings.classList.remove('h-hidden');
	}

	buildPillsWidget() {
		const pillsWidget = document.createElement('div');

		pillsWidget.classList.add('b-sliding-pills', 'js-sliding-pills', 'm-theme-' + this.theme);
		pillsWidget.appendChild(this.buildPills());

		return pillsWidget;
	}

	buildPills() {
		const pillsHolder = document.createElement('div');

		pillsHolder.classList.add('b-sliding-pills_headings', 'js-sliding-pills_headings');

		this.values.forEach((value, index) => {
			pillsHolder.appendChild(this.buildPill(value, index));
		});

		return pillsHolder;
	}

	buildPill(value, index) {
		const pillNode = document.createElement('button');
		const pillId = value.id ?? index;

		pillNode.className = `b-sliding-pills_heading js-sliding-pills_heading ${this.activeId === pillId ? 'active' : ''}`;
		pillNode.setAttribute('data-id', pillId);
		pillNode.textContent = value.name;
		pillNode.onclick = (e) => {
			if (e.target.classList.contains('active')) {
				return false;
			}

			this.activatePill(e.target);
		};

		return pillNode;
	}

	insertPillsInDOM(pillsWidget) {
		this.targetContainer.insertBefore(pillsWidget, this.targetContainer.firstChild);
	}

	activatePill(newActivePill) {
		this.notify('changeActivePills', { id: newActivePill.dataset.id });
		this.switchActivePill(newActivePill);
		this.setTogglerAnimationParams(newActivePill);
	}

	switchActivePill(newActivePill) {
		this.activePill.classList.remove('active');

		this.activePill = newActivePill;
		this.activePill.classList.add('active');
	}

	setTogglerAnimationParams(activePill) {
		const parent = activePill.parentElement;
		const parentOffsetPageRight = document.body.clientWidth - parent.getBoundingClientRect().right;

		parent.style.setProperty('--animationOffsetLeft', activePill.offsetLeft + 'px');
		parent.style.setProperty('--animationDuration', activePill.offsetLeft / ANIMATION_SPEED + 's');
		parent.style.setProperty('--animationOffsetRight', document.body.clientWidth - activePill.getBoundingClientRect().right - parentOffsetPageRight + 'px');
		parent.style.setProperty('--currentElWidth', activePill.clientWidth + 'px');
		parent.style.setProperty('--parentHeight', parent.clientHeight + 'px');
	}
}

export default SlidingPills;
