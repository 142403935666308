let intersectionObserver = null;
const isMobile = app.device.isMobileUserAgent();

const SCALE_CONFIG = {
	startTransitionPoint: isMobile ? 35 : 15,
	scalePoints: [
		{ seenPercents: 0, scaleCoef: isMobile ? 0 : 2.7, durationCoef: 700 },
		{ seenPercents: 15, scaleCoef: isMobile ? 0 : 4, durationCoef: isMobile ? 700 : 500 },
		{ seenPercents: 30, scaleCoef: isMobile ? 1 : 4, durationCoef: isMobile ? 100 : 500 },
		{ seenPercents: 40, scaleCoef: isMobile ? 1 : 5, durationCoef: isMobile ? 100 : 500 },
		{ seenPercents: 50, scaleCoef: isMobile ? 1.5 : 5, durationCoef: isMobile ? 100 : 500 }
	]
};

class StretchOnScroll {
/**
 * Represents a class that enables stretching and scaling an element on scroll.
 * @class StretchOnScroll
 * @constructor
 * @param {Object} config - Configuration object for initializing the StretchOnScroll instance.
 * @param {Element} config.moduleConstructorWrapper - The wrapper element for the module constructor.
 * @param {Element} config.imageBlock - The element to be stretched and scaled on scroll.
 */
	constructor(config) {
		this.moduleConstructorWrapper = config.moduleConstructorWrapper;
		this.imageBlock = config.imageBlock;

		initStretchOnScrollEvents(this.moduleConstructorWrapper);
	}
}

/**
 * Initializes events for the scaled image on page scroll.
 * @param {Element} moduleConstructorWrapper - The wrapper element for the module constructor.
 */
function initStretchOnScrollEvents(moduleConstructorWrapper) {
	const currentIntersectionObserver = getIntersectionObserver();
	const scrollContainer = moduleConstructorWrapper.querySelector('.js-stretched_container-outer');

	currentIntersectionObserver.observe(scrollContainer);
}

/**
 * Get the IntersectionObserver for the scaled image on page scroll (intersection with viewport).
 * @returns {IntersectionObserver} - The IntersectionObserver instance.
 */
function getIntersectionObserver() {
	if (intersectionObserver === null) {
		intersectionObserver = new IntersectionObserver(scaleOnScroll, {
			root: null,
			rootMargin: isMobile ? '-50% 0% 0% -50%' : '-5% 0% 0% 0%',
			threshold: app.util.buildThresholdList(0, 100)
		});
	}

	return intersectionObserver;
}

/**
 * Handles scaling of image based on IntersectionObserver entries.
 * @param {IntersectionObserverEntry[]} entries - An array of IntersectionObserver entries.
 */
function scaleOnScroll(entries) {
	entries.forEach((entry) => {
		if (entry.isIntersecting) {
			const imageBlock = entry.target.querySelector('.js-stretchOnScroll-element');
			const imageBlockWidth = imageBlock.offsetWidth;
			const scaledWidth = imageBlock.getBoundingClientRect().width;
			const maxScaleValue = Math.round((document.body.clientWidth / imageBlockWidth) * 1000) / 1000;

			const limiter = entry.target.querySelector('.js-stretched_container-limiter');
			const seenPercents = getSeenPercents(limiter);

			const scaleCoefficient = getCurrentScaleParamValue(seenPercents, 'scaleCoef');
			let scaleDuration = getCurrentScaleParamValue(seenPercents, 'durationCoef');

			limiter.classList.remove('m-ready', 'm-scale_transition');

			let currentScale = 1;
			const calculatedScale = 1 + (scaleCoefficient / 100) * seenPercents;
			const hasViewportWidth = imageBlockWidth * calculatedScale >= entry.boundingClientRect.width;

			if (hasViewportWidth && !isMobile) {
				currentScale = maxScaleValue;

				limiter.classList.add('m-ready');
			} else if (seenPercents > SCALE_CONFIG.startTransitionPoint) {
				currentScale = Math.round(calculatedScale * 10) / 10;
			}

			const scaleWidthDiff = scaledWidth - (scaledWidth / currentScale);

			scaleDuration = (scaleWidthDiff !== 0)
				? Math.round((scaleDuration / (scaleWidthDiff)) * 10000) / 10
				: getCurrentScaleParamValue(0, 'durationCoef');

			limiter.classList.add('m-scale_transition');
			limiter.style.setProperty('--currentScale', currentScale);
			limiter.style.setProperty('--scaleDuration', scaleDuration + 'ms');
		}
	});
}

/**
 * Retrieves the current value of specific scaling parameter based on seen percentages.
 * @param {number} seenPercents - The percentage of element visibility triggering the scaling.
 * @param {string} parameter - The parameter to retrieve.
 * @returns {number} The current value of the specified scaling parameter.
 */
function getCurrentScaleParamValue(seenPercents, parameter) {
	let currentParamValue = 0;
	const scaleConfig = SCALE_CONFIG.scalePoints;

	if (scaleConfig) {
		const seenPercentsValuesSorted = scaleConfig.sort((a, b) => a.seenPercents - b.seenPercents);

		seenPercentsValuesSorted.forEach((currentScaleRange) => {
			if (seenPercents >= currentScaleRange.seenPercents * 1) {
				currentParamValue = currentScaleRange[parameter] * 1;
			}
		});
	}

	return currentParamValue;
}

/**
 * Gets percentage of already scrolled block in viewport, when we scroll page DOWN
 * (0 - not in viewport yet, 100 - already not in viewport)
 * @param {Element} element scrolled element
 * @returns {number} The percent of scrolled block in viewport (0 - 100).
 */
function getSeenPercents(element) {
	const viewportHeight = window.innerHeight;
	const scrollY = window.scrollY;
	const elPosY = element.getBoundingClientRect().top + scrollY;
	const elHeight = element.offsetHeight;
	const distance = scrollY + viewportHeight - elPosY;
	const percentage = Math.round(distance / ((viewportHeight + elHeight) / 100));

	return percentage;
}

export default StretchOnScroll;
